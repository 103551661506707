import { Backup } from "@/api/backup.tsx"
import { api_fetch } from "@/api/client.tsx"
import { DeductionResponse, Message } from "@/api/deduction.tsx"
import { TaskStatus, TaskType } from "@/api/task.tsx"
import { LoadingSpinner } from "@/app/loading"
import { Button } from "@/components/ui/button.tsx"
import { Card } from "@/components/ui/card.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { displayFormattedDistributor, map_or } from "@/utils"
import { capitalCase } from "change-case"
import { format } from "date-fns"
import { Calendar, Check, CircleCheck, FileCog, FileUp, FileWarning, MailPlus, SendHorizonal } from "lucide-react"
import { StatusFlag } from "../status_state.tsx"

interface Update {
  date: string | Date
  title: string
  actor: string
  description?: string
  action?: any
}

const UPDATE_ICONS = {
  "Deduction created": <FileUp className="text-gray-400 ml-4" />,
  "Dispute created": <FileWarning className="text-gray-400 ml-4" />,
  "Backup parsed": <FileCog className="text-gray-400 ml-4" />,
  "Email sent": <SendHorizonal className="text-gray-400 ml-4" />,
  "Email received": <MailPlus className="text-gray-400 ml-4" />,
  "pending task": <CircleCheck className="text-gray-400 ml-4" />,
  "completed task": <Check className="text-plue-400 ml-4" />,
}

function getUpdateIcon(title: string) {
  for (const key in UPDATE_ICONS) {
    if (title.toLowerCase().startsWith(key.toLowerCase())) {
      return UPDATE_ICONS[key as keyof typeof UPDATE_ICONS]
    }
  }
  return null
}

const ACTOR_ICONS = {
  MarginWiz: <img class="block h-6 w-6 mr-2" src="/logo/rounded.png" alt="MarginWiz" />,
  unfi: <img class="block w-10 mr-2" src="/img/integration/unfi.png" alt="UNFI" />,
}

function parseEmailDomain(email: string) {
  const [_, domain] = email.split("@")
  return domain?.split(".")[0]
}

export function formatTaskType(taskType: TaskType) {
  switch (taskType) {
    case TaskType.FileDispute:
      return "File dispute"
    case TaskType.PullBackup:
      return "Pull backup"
    case TaskType.Followup:
      return "Follow-up"
    case TaskType.Validate:
      return "Validate deduction"
    case TaskType.Expense:
      return "Expense"
    case TaskType.MapAccountingCode:
      return "Map accounting code"
  }
}

async function handleTaskComplete(task_id: string) {
  const res = await api_fetch(`/task`, {
    method: "PUT",
    body: {
      status: TaskStatus.Completed,
      task_id,
    },
  })
  if (!res.ok) {
    console.error("Failed to mark task complete")
  }
  // todo: toast
  StatusFlag.set(flag => !flag)
}

function getTaskUpdate(deduction: DeductionResponse) {
  let task = deduction.task!
  return {
    date: task.created_at ?? "",
    title: `${capitalCase(task.status ?? "")} task`,
    description: `${task.user_email.split("@")[0] ?? ""} assigned to ${formatTaskType(task.type)}${
      task.backup_type ? ` (${task.backup_type.toLowerCase()})` : ""
    }${task.note ? `\nNote: ${task.note}` : ""}`,
    actor: task.assigner_email.split("@")[0] ?? "",
    action: task.status.toLowerCase() === TaskStatus.Pending
      ? (
        <Button onClick={() => handleTaskComplete(task.id)} variant="secondary">
          Mark complete
        </Button>
      )
      : (
        ""
      ),
  }
}

export function Updates({
  deduction,
  backup,
  messages,
  loading = false,
  className = "h-96",
}: {
  deduction: DeductionResponse
  backup?: Backup
  messages: Message[]
  loading?: boolean
  className?: string
}) {
  if (loading) {
    return (
      <Card className={className}>
        <LoadingSpinner color="plue-500" />
      </Card>
    )
  }

  const updates: Update[] = [
    {
      date: deduction.created_at,
      title: "Deduction created",
      description: `Synced from ${displayFormattedDistributor(deduction.source, deduction.original_source)}`,
      actor: "MarginWiz",
    },
  ]
  if (backup) {
    updates.unshift({
      date: backup.created_at,
      title: "Backup parsed",
      description: "See details in table below",
      // todo: add link to the file here also for convenience?
      actor: "MarginWiz",
    })
  }
  messages?.forEach(message => {
    if (message.attachments) {
      const isFirstEmail = message.sequence === 0
      // email.attachments.some(attachment => attachment.s3_uri.includes("dispute"))
      updates.unshift({
        date: message.date,
        title: isFirstEmail ? "Email sent" : "Email received",
        description: isFirstEmail ? "Dispute / backup request filed" : "Response received",
        actor: map_or(message.from.email, "MarginWiz", parseEmailDomain),
      })
    }
  })
  if (deduction.task?.created_at) {
    updates.unshift(getTaskUpdate(deduction))
  }

  return (
    <Card className={`${className} overflow-y-auto`}>
      <div class="flex justify-between items-center m-4">
        <h2 class="flex text-xl">
          <Calendar class="h-6 w-6" />
          <span class="ml-2">Updates</span>
        </h2>
      </div>
      <div class="px-4 w-full flex justify-center">
        <Separator class="w-1/2" />
      </div>
      <div class="flex flex-col gap-4 mt-4 mb-4">
        {updates.map((update, i) => (
          <>
            <div class="flex gap-2 items-start ">
              <div class="flex items-center justify-center">{getUpdateIcon(update.title)}</div>
              <div class="flex flex-col grow">
                <div class="text-lg ">{update.title}</div>
                <span class="text-gray-500">{update.description}</span>
                <div class="flex justify-between w-full mt-3">
                  <div class="flex items-center">
                    {update.action || ACTOR_ICONS[update.actor as keyof typeof ACTOR_ICONS]}
                  </div>{" "}
                  <div class="flex items-center">
                    <Calendar className="text-gray-500 h-4 w-4 " />
                    <div class="text-sm text-gray-500 text-right ml-2 mr-4">
                      {format(update.date as Date, "PPP")}
                    </div>
                  </div>
                  {" "}
                </div>
              </div>
            </div>
            {i < updates.length - 1 && <hr class="border-t border-gray-200 ml-12 mx-4" />}
          </>
        ))}
      </div>
    </Card>
  )
}
