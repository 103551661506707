import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/utils";

export type BadgeVariantOptionType = "new" | "review" | "validated" | "won" | "pending" | "disputed" | "backup_requested" | "backup_received" | "processing" | "disputable" | "default" | "secondary" | "destructive" | "outline" | undefined;
const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-sm transition-colors focus:outline-hidden focus:ring-1 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        // needs action - red
        new: "border-transparent bg-red-200 text-red-800 hover:bg-red-300",
        
        // needs action - amber
        backup_received: "border-transparent bg-amber-200 text-amber-800 hover:bg-amber-300",
        review: "border-transparent bg-amber-200 text-amber-800 hover:bg-amber-300",
        disputable: "border-transparent bg-amber-200 text-amber-800 hover:bg-amber-300",
        
        // partial action taken - yellow
        expensable: "border-transparent bg-yellow-200 text-yellow-800 hover:bg-yellow-300",
        backup_requested: "border-transparent bg-yellow-200 text-yellow-800 hover:bg-yellow-300",
        disputed: "border-transparent bg-yellow-200 text-yellow-800 hover:bg-yellow-300",
        pending: "border-transparent bg-yellow-200 text-yellow-800 hover:bg-yellow-300",
        
        // don't need to work on it - slate
        processing: "border-transparent bg-slate-200 text-slate-800 hover:bg-slate-300",
        archived: "border-transparent bg-slate-200 text-slate-800 hover:bg-slate-300",
        lost: "border-transparent bg-slate-200 text-slate-800 hover:bg-slate-300",
        misc: "border-transparent bg-slate-200 text-slate-800 hover:bg-slate-300",
        
        // celebration - green
        validated: "border-transparent bg-green-200 text-green-800 hover:bg-green-300",
        won: "border-transparent bg-green-200 text-green-800 hover:bg-green-300",

        // default variants
        default: "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary: "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        outline: "text-foreground",
        warning: "border-transparent bg-yellow-300 text-yellow-900 hover:bg-yellow-400",
        success: "border-transparent bg-green-200 text-green-800 hover:bg-green-300",
        destructive: "border-transparent bg-red-200 text-red-800 hover:bg-red-300",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants };
