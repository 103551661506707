import { api_fetch } from "@/api/client"
import { DeductionResponse } from "@/api/deduction"
import { Badge } from "@/components/ui/badge"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { CATEGORY_COLORS } from "@/deductions/category_breakdown"
import { classes } from "@/utils"
import { capitalCase } from "change-case"
import { useState } from "preact/hooks"
import { z } from "zod"
import { StatusFlag } from "./status_state"
import { CATEGORY_FORM_SCHEMA, CategoryForm } from "./table/category_form"

interface CategoryCellProps {
  deduction: DeductionResponse
  className?: string
}

const colors = [
  "bg-amber-100",
  "text-amber-700",
  "hover:bg-amber-200",
  "bg-emerald-100",
  "text-emerald-700",
  "hover:bg-emerald-200",
  "bg-fuchsia-100",
  "text-fuchsia-700",
  "hover:bg-fuchsia-200",
  "bg-sky-100",
  "text-sky-700",
  "hover:bg-sky-200",
  "bg-rose-100",
  "text-rose-700",
  "hover:bg-rose-200",
  "bg-slate-100",
  "text-slate-700",
  "hover:bg-slate-200",
  "bg-teal-100",
  "text-teal-700",
  "hover:bg-teal-200",
  "bg-lime-100",
  "text-lime-700",
  "hover:bg-lime-200",
  "bg-indigo-100",
  "text-indigo-700",
  "hover:bg-indigo-200",
  "bg-emerald-100",
  "text-emerald-700",
  "hover:bg-emerald-200",
  "bg-lime-100",
  "text-lime-700",
  "hover:bg-lime-200",
]

export function CategoryCell({ deduction, className }: CategoryCellProps) {
  const [categoryPopoverOpen, setCategoryPopoverOpen] = useState(false)
  const value = deduction.category as keyof typeof CATEGORY_COLORS
  const color = value ? CATEGORY_COLORS[value] : "gray"

  async function handleSubmit(values: z.infer<typeof CATEGORY_FORM_SCHEMA>) {
    const res = await api_fetch(`/deduction/${deduction.id}/category`, {
      method: "POST",
      body: values,
    })

    if (!res.ok) {
      console.error("Failed to update category")
      return false
    }

    setCategoryPopoverOpen(false)
    StatusFlag.set(flag => !flag)
    return true
  }

  if (!deduction.is_deduction) {
    return null
  }

  const displayValue = value ? capitalCase(value) : "Misc"

  return (
    <Popover modal={true} open={categoryPopoverOpen} onOpenChange={setCategoryPopoverOpen}>
      <PopoverTrigger
        onClick={(e: MouseEvent) => {
          e.preventDefault()
          e.stopImmediatePropagation()
          setCategoryPopoverOpen(!categoryPopoverOpen)
        }}
      >
        <Badge
          className={classes(
            `bg-${color}-100 text-${color}-700 hover:bg-${color}-200`,
            "text-center",
            className,
          )}
        >
          {displayValue}
        </Badge>
      </PopoverTrigger>
      <PopoverContent collisionPadding={200} className="text-plue-900">
        <CategoryForm
          initialValue={value}
          deductionId={deduction.id}
          onSubmit={handleSubmit}
        />
      </PopoverContent>
    </Popover>
  )
}
