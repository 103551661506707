"use client"

import { DeductionResponse } from "@/api/deduction.tsx"
import { Badge } from "@/components/ui/badge.tsx"

import { Checkbox } from "@/components/ui/checkbox.tsx"
import { cellCurrencyFormatter } from "@/deductions/detail/backup_columns.tsx"
import { handleViewS3Uri } from "@/deductions/detail/files.tsx"
import { AccessorColumnDef, DisplayColumnDef, GroupColumnDef, RowSelectionInstance } from "@tanstack/react-table"

import { api_fetch } from "@/api/client.tsx"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { toast } from "@/components/ui/use-toast.ts"
import { CATEGORY_COLORS } from "@/deductions/category_breakdown"
import { DataTableState } from "@/deductions/table_state"
import { displayFormattedDistributor, displayFormattedImportSource, isSuperCoffeeUser } from "@/utils"
import { differenceInDays, formatDistanceToNow } from "date-fns"
import {
  Check,
  CircleCheckIcon,
  Copy,
  Dot,
  Ellipsis,
  Eye,
  File,
  FileCheck,
  FileCog,
  Paperclip,
  Receipt,
  Search,
  ShieldQuestion,
  Trophy,
} from "lucide-react"
import { useState } from "preact/hooks"
import { CategoryCell } from "../category_cell"
import { STATUS_VALUES, StatusSelect } from "../status_select.tsx"
import { StatusFlag } from "../status_state.tsx"
import { Actions } from "./actions"
import { ColumnHeader } from "./column_header.tsx"
import { defaultFilter } from "./data_table.tsx"
import { createDateColumn } from "./date_column.tsx"
import { copyToClipboard } from "./util.tsx"

export const STATUS_ICONS = {
  new: <Dot className="h-4 w-4 text-orange-700" />,
  review: <Eye className="h-4" />,
  validated: <CircleCheckIcon fill="green" className="h-4 text-white" />,
  expensable: <Dot className="h-4 text-green-500" />,
  won: <Trophy className="h-4" />,
  pending: <Ellipsis className="h-4" />,
  disputed: <Ellipsis className="h-4" />,
  backup_requested: <FileCog className="h-4" />,
  backup_received: <FileCheck className="h-4" />,
  processing: <Receipt className="h-4" />,
  disputable: <ShieldQuestion className="h-4" />,
}

// AI notes on filtering:
// The key difference lies in how the accessorKey and id fields are used in combination with accessorFn:
// When a column has an accessorKey, it automatically creates a default filter function that works with that key's value, even if there's an accessorFn. The accessorFn in this case is only used for display/sorting purposes.
// When a column has an id instead of an accessorKey, it relies entirely on the accessorFn for both display and filtering, which is why it needs an explicit filterFn.

export const invoiceAmountColumn: DeductionTableColumn = {
  accessorKey: "invoice_amount",
  enableHiding: false,
  header: ({ column }) => <ColumnHeader className="justify-end" column={column} title="Amount" />,
  cell: cellCurrencyFormatter,
  sortingFn: (rowA, rowB, columnId) => {
    let a = rowA.original.invoice_amount
    let b = rowB.original.invoice_amount
    return Math.abs(a) - Math.abs(b)
  },
}

export const discountAmountColumn: DeductionTableColumn = {
  accessorKey: "discount_amount",
  enableHiding: false,
  accessorFn: row => (row.discount_amount || 0),
  header: ({ column }) => <ColumnHeader className="justify-end" column={column} title="Discount" />,
  cell: cellCurrencyFormatter,
  sortingFn: (rowA, rowB, columnId) => {
    const a = parseFloat(rowA.getValue(columnId) as string) || 0
    const b = parseFloat(rowB.getValue(columnId) as string) || 0
    return a - b
  },
}

export const paymentDaysColumn: DeductionTableColumn = {
  id: "payment_days",
  accessorFn: row => {
    if (!row.check_date) return null

    const invoiceDate = new Date(row.invoice_date)
    const checkDate = new Date(row.check_date)
    return differenceInDays(checkDate, invoiceDate)
  },
  header: ({ column }) => <ColumnHeader column={column} title="Payment Days" />,
  cell: ({ getValue }) => {
    const days = getValue() as number
    if (!days) return null

    let color = "won"
    // switch if things start looking cluttered / too colorful
    // let color = "outline"
    if (days > 15) {
      color = "lost"
    } else if (days >= 10) {
      color = "review"
    }

    // @ts-ignore
    return <Badge variant={color}>{days} days</Badge>
  },
}

function CopyableCell({ value }: { value: string }) {
  const [showCopyConfirmed, setShowCopyConfirmed] = useState<boolean>(false)

  function handleCopy(e: MouseEvent) {
    e.preventDefault()
    e.stopImmediatePropagation()
    copyToClipboard(value)
    setShowCopyConfirmed(true)
    setTimeout(() => {
      setShowCopyConfirmed(false)
    }, 2000)
  }

  return (
    <div className="flex items-center">
      <span>{value}</span>
      {showCopyConfirmed
        ? <Check className="ml-2 h-4 w-4 text-green-500" />
        : <Copy className="ml-2 h-4 w-4 cursor-pointer hover:text-plue-500" title="Copy Value" onClick={handleCopy} />}
    </div>
  )
}

export const invoiceNumberColumn: DeductionTableColumn = {
  accessorKey: "invoice_number",
  enableHiding: false,
  header: ({ column }) => <ColumnHeader column={column} title="Invoice #" />,
  cell: ({ getValue }) => {
    const value = getValue() as string
    return <CopyableCell value={value} />
  },
}

export const importSourceColumn: DeductionTableColumn = {
  accessorKey: "import_source",
  header: ({ column }) => <ColumnHeader column={column} title="Import Source" />,
  accessorFn: row => displayFormattedImportSource(row.import_source),
  filterFn: defaultFilter,
}

export const distributorColumn: DeductionTableColumn = {
  accessorKey: "source",
  accessorFn: row => displayFormattedDistributor(row.source, row.original_source),
  enableHiding: false,
  header: ({ column }) => <ColumnHeader column={column} title="Customer" />,
  filterFn: defaultFilter,
}

export const sourceNumberColumn: DeductionTableColumn = {
  id: "source_number",
  accessorFn: row => {
    if (row.original_source) {
      return row.original_source.replace(/\D/g, "")
    }
    return ""
  },
  header: ({ column }) => <ColumnHeader column={column} title="Customer #" />,
  filterFn: defaultFilter,
}

export const dcColumn: DeductionTableColumn = {
  accessorKey: "dc",
  header: ({ column }) => <ColumnHeader column={column} title="DC" />,
}

export const dcNameColumn: DeductionTableColumn = {
  accessorKey: "dc_name",
  header: ({ column }) => <ColumnHeader column={column} title="DC Name" />,
  enableGlobalFilter: false,
}

export const statusColumn: DeductionTableColumn = {
  accessorKey: "status_value",
  header: ({ column }) => (
    <ColumnHeader column={column} title="Stage" choices={Object.keys(STATUS_VALUES)} onSelectChoice={choice => {
      DataTableState.set(s => {
        let columnFilters = s.columnFilters.filter(f => f.id !== "status_value")
        if (choice !== "all") {
          columnFilters.push({ id: "status_value", value: choice })
        }
        return { ...s, columnFilters }
      })
    }}/>
  ),
  cell: cell => {
    if (cell.row.original.is_prepayment || cell.row.original.is_repayment) {
      return null
    } else {
      return <StatusSelect deduction={cell.row.original} />
    }
  },
  filterFn: defaultFilter,
}

export const filesColumn: DeductionTableColumn = {
  id: "files", // named this so it's consistent in the column dropdown filter
  header: ({ column }) => <ColumnHeader column={column} title="Files" />,
  enableGlobalFilter: false,
  cell: cell => {
    let ded = cell.row.original
    let buttons = []
    let backup_s3_uri = ded.backup_s3_uri
    if (backup_s3_uri) {
      buttons.push(
        <div
          onClick={handleViewS3Uri(backup_s3_uri)}
          className="cursor-pointer text-primary underline pr-2"
        >
          <Paperclip className="w-4 h-4" />
        </div>,
      )
    }
    let check_s3_uri = ded.check_s3_uri
    if (check_s3_uri) {
      buttons.push(
        <div
          onClick={handleViewS3Uri(check_s3_uri)}
          className="cursor-pointer text-primary underline pr-2"
        >
          <File className="w-4 h-4" />
        </div>,
      )
    }

    if (ded.user_files) {
      for (const user_file of ded.user_files) {
        buttons.push(
          <div onClick={handleViewS3Uri(user_file.s3_uri)} className="cursor-pointer text-primary underline pr-2" >
            <FileCog className="w-4 h-4" />
          </div>,
        )
      }
    }

    // if super & not kehe / unfi, link to google drive search with a magnifying glass icon
    if (
      isSuperCoffeeUser(ded.org_id) 
      && !["kehe", "unfi"].includes(ded.source.toLowerCase())
    ) {
      buttons.push(
        <a
          href={`https://drive.google.com/drive/search?q=${encodeURIComponent(ded.invoice_number)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer text-primary underline pr-2"
        >
          <Search className="w-4 h-4" />
        </a>,
      )
    }
    if (!buttons.length) return ""
    return <div class="flex">{buttons}</div>
  },
}

export const actionsColumn: DeductionTableColumn = {
  id: "actions",
  header: ({ column }) => <ColumnHeader column={column} title="" />,
  enableGlobalFilter: false,
  cell: ({ row }) => {
    return <Actions deduction={row.original} />
  },
}

export const taskColumn: DeductionTableColumn = {
  id: "task",
  accessorFn: row => `${row.task?.status}: ${row.task?.user_email?.split("@")[0]}`,
  header: ({ column }) => <ColumnHeader column={column} title="Task" />,
  enableSorting: true,
  cell: ({ row }) => {
    let task_user_email = row.original.task?.user_email
    if (!task_user_email) return ""

    return `${row.original.task?.status}: ${task_user_email.split("@")[0]}`
  },
  filterFn: defaultFilter,
}

export const taskAssignedAtColumn: DeductionTableColumn = {
  id: "task_assigned_at",
  accessorFn: row => row.task?.created_at,
  header: ({ column }) => <ColumnHeader column={column} title="Assigned" />,
  cell: ({ getValue }) => {
    const value = getValue() as string
    if (!value) return ""
    return formatDistanceToNow(new Date(value), { addSuffix: true })
  },
  // @ts-ignore
  filterFn: "dateFilter" as const,
}

export const selectColumn: DeductionTableColumn = {
  id: "select",
  header: ({ table }: { table: RowSelectionInstance<DeductionResponse> }) => (
    <Checkbox
      checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
      onCheckedChange={(value: any) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onClick={(e: MouseEvent) => {
        e.preventDefault()
        e.stopImmediatePropagation()
        row.toggleSelected()
      }}
      aria-label="Select row"
      className="h-6 w-6"
    />
  ),
  enableSorting: false,
  enableHiding: false,
  enableGlobalFilter: false,
}

export const checkAmountColumn: DeductionTableColumn = {
  accessorKey: "check_amount",
  header: ({ column }) => <ColumnHeader className="justify-end" column={column} title="Check Amount" />,
  cell: cellCurrencyFormatter,
  filterFn: defaultFilter,
}

export const categoryColumn: DeductionTableColumn = {
  accessorKey: "category",
  header: ({ column }) => (
    <ColumnHeader column={column} title="Category"
                  choices={Object.keys(CATEGORY_COLORS)}
                  onSelectChoice={(choice: string) => {
                    DataTableState.set(s => {
                      let columnFilters = s.columnFilters.filter(f => f.id !== "category")
                      if (choice !== "all") {
                        columnFilters.push({ id: "category", value: choice })
                      }
                      return { ...s, columnFilters }
                    })
                  }}
    />
  ),
  cell: ({ row }) => <CategoryCell deduction={row.original} />,
  filterFn: defaultFilter,
}

export const descriptionColumn: DeductionTableColumn = {
  accessorKey: "description",
  header: ({ column }) => <ColumnHeader column={column} title="Description" />,
}
export const retailerColumn: DeductionTableColumn = {
  accessorKey: "retailer_name",
  header: ({ column }) => <ColumnHeader column={column} title="Retailer" />,
}

export const retailerInvoiceNumberColumn: DeductionTableColumn = {
  accessorKey: "retailer_invoice_number",
  header: ({ column }) => <ColumnHeader column={column} title="Retailer Invoice Number" />,
}

export const productLineColumn: DeductionTableColumn = {
  accessorKey: "product_line",
  header: ({ column }) => <ColumnHeader column={column} title="Product Line" />,
}

export const checkNumberColumn: DeductionTableColumn = {
  accessorKey: "check_number",
  header: ({ column }) => <ColumnHeader className="justify-end" column={column} title="Check Number" />,
  cell: ({ getValue }) => {
    const value = getValue() as string
    return <CopyableCell value={value} />
  },
}

const DEDUCTION_METHODS = [
  "Pay via AB",
  "INDIRECT",
  "Pay Customer Directly",
  "DEDUCT"
] as const

type DeductionMethod = typeof DEDUCTION_METHODS[number]

export const customDeductionMethodColumn: DeductionTableColumn = {
  accessorKey: "custom_deduction_method",
  header: ({ column }) => <ColumnHeader column={column} title="Deduction Method" />,
  enableHiding: true,
  filterFn: defaultFilter,
  cell: ({ row, getValue }) => {
    // Hide column if not Super Coffee
    if (!isSuperCoffeeUser(row.original.org_id)) {
      return null
    }

    const initialValue = getValue() as DeductionMethod
    const [value, setValue] = useState<DeductionMethod | undefined>(initialValue)

    const handleCellClick = (e: MouseEvent) => {
      e.stopPropagation() // Prevent row click
    }

    const saveValue = async (newValue: DeductionMethod) => {
      if (newValue === value) return "" // Skip if unchanged

      setValue(newValue)
      const res = await api_fetch(`/deduction/${row.original.id}`, {
        method: "PATCH",
        body: { custom_deduction_method: newValue },
      })

      if (!res.ok) {
        console.error("Failed to update custom deduction method")
        setValue(initialValue) // Reset on error
        toast({
          title: "Error",
          description: "Failed to update deduction method",
          variant: "destructive",
        })
        return
      }

      toast({
        title: "Success",
        description: "Deduction method updated",
      })
      StatusFlag.set(flag => !flag) // Trigger refresh
    }

    return (
      <div onClick={handleCellClick}>
        <Select
          value={value}
          onValueChange={saveValue}
        >
          <SelectTrigger className="h-8 w-[180px]">
            <SelectValue placeholder="Select method..." />
          </SelectTrigger>
          <SelectContent>
            {DEDUCTION_METHODS.map(method => (
              <SelectItem key={method} value={method}>
                {method}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    )
  },
}

const getPriority = (val: string): number => {
  switch (val?.toLowerCase()) {
    case "red":
      return 2
    case "yellow":
      return 1
    case "green":
      return 0
    default:
      return -1
  }
}
// named flag for now for super-coffee bc they're the only one that uses it
export const flagColumn: DeductionTableColumn = {
  id: "flag",
  accessorFn: row => {
    if (!row.reason_codes || !Array.isArray(row.reason_codes)) return ""
    
    const sortedReasonCodes = [...row.reason_codes].filter(rc => rc.dispute_likelihood).sort((a, b) => {
      return getPriority(b.dispute_likelihood as string) - getPriority(a.dispute_likelihood as string)
    })
    
    return sortedReasonCodes[0]?.dispute_likelihood || ""
  },
  header: ({ column }) => <ColumnHeader column={column} title="Flag" />,
  cell: ({ getValue }) => {
    const value = (getValue() as string)?.toLowerCase()
    if (!value) return null

    const colorMap: Record<string, "destructive" | "warning" | "success"> = {
      red: "destructive",
      yellow: "warning",
      green: "success",
    }

    return <Badge variant={colorMap[value] || "default"}>{value}</Badge>
  },
  sortingFn: (rowA, rowB, columnId) => {
    const a = rowA.getValue(columnId) as string
    const b = rowB.getValue(columnId) as string

    return getPriority(a) - getPriority(b)
  },
}

export const accountingStatusColumn: DeductionTableColumn = {
  accessorKey: "accounting_status",
  header: ({ column }) => <ColumnHeader column={column} title="Accounting Status" />,
}

export const customCategoryColumn: DeductionTableColumn = {
  id: "custom_category",
  accessorFn: (row) => {
    if (!row.reason_codes || !Array.isArray(row.reason_codes)) return ""
    const uniqueCategories = [...new Set(
      row.reason_codes
        .map(rc => rc.custom_category)
        .filter(Boolean)
    )]
    return uniqueCategories.join(", ")
  },
  header: ({ column }) => <ColumnHeader column={column} title="Custom Category" />,
  filterFn: (row, columnId, filterValue) => {
    const categories = row.getValue(columnId) as string
    return categories.toLowerCase().includes(filterValue.toLowerCase())
  }
}

export const hasSplitsColumn: DeductionTableColumn = {
  accessorKey: "has_splits",
  header: ({ column }) => <ColumnHeader column={column} title="Split" />,
  accessorFn: row => row.has_splits ? "Yes" : "No",
}

export const isParentColumn: DeductionTableColumn = {
  accessorKey: "is_parent",
  header: ({ column }) => <ColumnHeader column={column} title="Parent" />,
  accessorFn: row => row.is_parent ? "Yes" : "No",
}

export type TableColumn<T> =
  | DisplayColumnDef<T, unknown>
  | GroupColumnDef<T, unknown>
  | (AccessorColumnDef<T, unknown> & {
  accessorKey: keyof T
})

export type DeductionTableColumn = TableColumn<DeductionResponse>

export const BASE_COLUMNS: DeductionTableColumn[] = [
  selectColumn,
  createDateColumn("invoice_date", "Invoice Date", {
    enableGlobalFilter: true,
    enableHiding: false,
  }),
  invoiceNumberColumn,
  sourceNumberColumn,
  distributorColumn,
  categoryColumn,
  descriptionColumn,
  retailerColumn,
  invoiceAmountColumn,
  checkAmountColumn,
  checkNumberColumn,
  createDateColumn("execution_date", "Execution Date", {
    enableGlobalFilter: true,
    enableHiding: true,
  }),
  statusColumn,
  filesColumn,
  actionsColumn,
  taskColumn,
  taskAssignedAtColumn,
  dcColumn,
  dcNameColumn,
  retailerInvoiceNumberColumn,
  productLineColumn,
  createDateColumn("check_date", "Check Date", { enableGlobalFilter: true, enableHiding: true }),
  flagColumn,
  accountingStatusColumn,
  customDeductionMethodColumn,
  customCategoryColumn,
  importSourceColumn,
  hasSplitsColumn,
  isParentColumn,
  {
    accessorKey: "validated",
    enableHiding: true,
  },
]
