import { Deduction } from "@/api/deduction.tsx"

export enum DisputeType {
  Backup = "backup",
  Chargeback = "chargeback",
  EarlyPay = "earlypay",
  Unknown = "unknown",
}

export interface FileDisputeForm {
  deduction_id: string
  type: DisputeType
  message: string
}

export function build_dispute_form(data: FileDisputeForm): FormData {
  let formData = new FormData()
  formData.append("deduction_id", data.deduction_id)
  formData.append("dispute_type", data.type)
  formData.append("message", data.message)
  return formData
}

export async function bulk_request_backup(deductions: Deduction[]) {
  let forms = []
  for (const d of deductions) {
    let message = `Please provide backup for this charge. The deduction invoice number is ${d.invoice_number}.`
    forms.push(
      build_dispute_form({
        deduction_id: d.id,
        type: DisputeType.Backup,
        message,
      }),
    )
  }
  for (const form of forms) {
    let res = await fetch("/api/dispute", {
      method: "POST",
      credentials: "same-origin",
      body: form,
    })
    if (!res.ok) {
      console.error("Failed to request backup")
    }
  }
}

export interface FileDispute {
  deduction: Deduction
  message: string
  dispute_amount: number
  files: File[]
  existing_file_uris: string // concatenated string of existing file uris, comma separated
}

export async function file_dispute(d: FileDispute) {
  let type = d.deduction.is_deduction ? DisputeType.Chargeback : DisputeType.EarlyPay
  let form = build_dispute_form({
    deduction_id: d.deduction.id,
    type,
    message: d.message,
  })
  form.append("dispute_amount", d.dispute_amount.toString())
  for (const f of d.files) {
    form.append("files", f)
  }
  form.append("existing_files", d.existing_file_uris)
  return await fetch("/api/dispute", {
    method: "POST",
    credentials: "same-origin",
    body: form,
  })
}

if (import.meta.vitest) {
  const mockFetch = vi.fn()
  vi.stubGlobal("fetch", mockFetch)

  describe("dispute helpers", () => {
    describe("build_dispute_form", () => {
      it("should create a FormData object with correct fields", () => {
        const data = {
          deduction_id: "123",
          type: DisputeType.Backup,
          message: "Test message",
        }
        const formData = build_dispute_form(data)

        expect(formData.get("deduction_id")).toBe("123")
        expect(formData.get("dispute_type")).toBe("backup")
        expect(formData.get("message")).toBe("Test message")
      })
    })

    describe("bulk_request_backup", () => {
      beforeEach(() => {
        mockFetch.mockReset()
        mockFetch.mockResolvedValue({ ok: true })
        vi.spyOn(console, "error").mockImplementation(() => {})
      })

      it("should create and send a form for each deduction", async () => {
        const deductions: Deduction[] = [
          { id: "1", invoice_number: "INV001" },
          { id: "2", invoice_number: "INV002" },
        ] as Deduction[]

        await bulk_request_backup(deductions)

        expect(mockFetch).toHaveBeenCalledTimes(2)
        expect(mockFetch).toHaveBeenCalledWith(
          "/api/dispute",
          expect.objectContaining({
            method: "POST",
            credentials: "same-origin",
          }),
        )
      })

      it("should log an error if the request fails", async () => {
        mockFetch.mockResolvedValue({ ok: false })
        const deductions: Deduction[] = [{ id: "1", invoice_number: "INV001" }] as Deduction[]

        await bulk_request_backup(deductions)

        expect(console.error).toHaveBeenCalledWith("Failed to request backup")
      })
    })

    describe("file_dispute", () => {
      beforeEach(() => {
        mockFetch.mockReset()
        mockFetch.mockResolvedValue({ ok: true })
      })

      it("should create and send a form with correct data", async () => {
        const dispute = {
          deduction: { id: "123" } as Deduction,
          message: "Test dispute",
          dispute_amount: 100,
          files: [new File([""], "test.pdf")],
          existing_file_uris: "",
        }

        await file_dispute(dispute)

        expect(mockFetch).toHaveBeenCalledWith(
          "/api/dispute",
          expect.objectContaining({
            method: "POST",
            credentials: "same-origin",
          }),
        )

        const calledFormData = mockFetch.mock.calls[0][1].body as FormData
        expect(calledFormData.get("deduction_id")).toBe("123")
        expect(calledFormData.get("dispute_type")).toBe("earlypay")
        expect(calledFormData.get("message")).toBe("Test dispute")
        expect(calledFormData.get("dispute_amount")).toBe("100")
        expect(calledFormData.get("files")).toBeInstanceOf(File)
      })

      it("should include both new files and existing file URIs in form data", () => {
        const mockDeduction = {
          id: "123",
          invoice_number: "INV123",
          // ... other required deduction fields
        }

        const mockFiles = [new File(["test"], "test.txt")]
        const mockExistingFileUris = "s3://bucket/existing1.pdf,s3://bucket/existing2.pdf"

        const formData = new FormData()
        formData.append("deduction_id", mockDeduction.id)
        formData.append("message", "test message")
        formData.append("dispute_amount", "100")
        mockFiles.forEach(file => formData.append("files", file))
        formData.append("existing_file_uris", mockExistingFileUris)

        // Test the actual file_dispute function with these parameters
        const result = file_dispute({
          // @ts-ignore
          deduction: mockDeduction,
          message: "test message",
          dispute_amount: 100,
          files: mockFiles,
          existing_file_uris: mockExistingFileUris,
        })

        // Add appropriate assertions based on your implementation
      })
    })
  })
}
