import { fetch_query_data } from "@/api"
import { LoadingSpinner } from "@/app/loading"
import { BreakdownData, FALLBACK_COLORS } from "@/components/breakdown"
import { StatusFlag } from "@/deductions/status_state"
import { DataTableState } from "@/deductions/table_state"
import { putTransactionType } from "@/global_filter"
import { currencyFormatter, index_map, useAsyncEffect } from "@/utils"
import { BarList } from "@tremor/react"
import { useState } from "preact/compat"

export const CATEGORY_COLORS = {
  "Retailer Promotion": "amber",
  "Operational Issues": "emerald",
  "Free Fill": "fuchsia",
  "Distributor Promotion": "sky",
  "Contractual Fees": "rose",
  "Misc": "slate",
  "Freight": "teal",
  "Invoice Discrepancy": "lime",
  "Slotting": "indigo",
  "Spoilage": "emerald",
  "Invoice Adjustment": "lime",
}

interface CategoryData extends BreakdownData {
  count: number
  originalName: string
}

export function CategoryBreakdown() {
  const [data, setData] = useState<CategoryData[]>([])
  const [loading, setLoading] = useState(true)
  const [start, end, distributor] = DataTableState.use(u => [u.startDate, u.endDate, u.distributor])
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    let res = await fetch_query_data("category", { start, end, distributor })
    setLoading(false)
    if (!res.ok) {
      setData([])
      throw new Error("Failed to fetch data")
    }

    let { rows, headers } = res.value
    let headerIndex = index_map(headers)

    rows = rows.filter(row => row[headerIndex.name] !== "Total")
    rows.sort((a, b) => (b[headerIndex.value] as number) - (a[headerIndex.value] as number))

    let data = rows.map((row, index) => {
      const name = row[headerIndex.name] as keyof typeof CATEGORY_COLORS
      const count = row[headerIndex.count] as number
      return {
        name: `${name} (${count})`,
        originalName: name,
        value: Math.abs(row[headerIndex.value] as number),
        count,
        color: CATEGORY_COLORS[name] || FALLBACK_COLORS[index % FALLBACK_COLORS.length],
      }
    })
    setData(data)
  }, [start, end, distributor, statusFlag])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  return (
    <div className="flex">
      <BarList
        data={data}
        className="mt-2 max-w-2xl grow"
        valueFormatter={currencyFormatter}
        onValueChange={(item: CategoryData) => {
          DataTableState.set(s => ({
            ...s,
            search: "",
            transactionTypes: putTransactionType(s.transactionTypes, "deduction"),
            columnFilters: [{ id: "category", value: item.originalName }],
            pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
          }))
        }}
      />
    </div>
  )
}

