import { DeductionResponse } from "@/api/deduction.tsx"
import { stopPropagation } from "@/utils"
import { ExternalLinkIcon, ShieldAlert } from "lucide-react"
import { useLocation } from "wouter-preact"
import { getCvsDisputeUrl, getDisputeAction } from "./dispute_utils"
import { FileDisputeDrawer } from "./file_dispute_drawer.tsx"
import { Notes } from "./notes.tsx"

function getDisputeActionIcon(deduction: DeductionResponse) {
  // If already disputed, show green shield
  if (deduction.dispute_id) {
    return <ShieldAlert className="h-5 w-5 text-green-500 cursor-pointer" />
  }

  let action = getDisputeAction(deduction, null)

  if (!action || action.type !== "can_file") {
    return null
  }

  if (deduction.source.toLowerCase() === "cvs") {
    return (
      <ShieldAlert
        onClick={() => window.open(getCvsDisputeUrl(deduction), "_blank")}
        className="h-5 w-5 text-red-600 cursor-pointer"
      />
    )
  }

  return (
    <FileDisputeDrawer
      deduction={deduction}
      trigger={<ShieldAlert className="h-5 w-5 cursor-pointer text-red-600" />}
    />
  )
}

export function Actions({ deduction }: { deduction: DeductionResponse }) {
  const [location] = useLocation()

  return (
    <div className="flex justify-between items-center gap-1" onClick={stopPropagation}>
      <Notes deduction_id={deduction.id} notes={deduction.notes} />
      {getDisputeActionIcon(deduction)}
      <ExternalLinkIcon
        className="h-5 w-5 cursor-pointer text-primary underline"
        onClick={() =>
          window.open(
            location.includes("accounting")
              ? `/split/${deduction.id}`
              : `/deduction/${deduction.id}`,
            "_blank",
          )}
      />
    </div>
  )
}
