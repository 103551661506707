import { DeductionResponse, Dispute } from "@/api/deduction"
import { DisputeType } from "@/api/dispute"
import { Option } from "@/utils"
import { addDays, differenceInDays } from "date-fns"

interface DisputeConfig {
  maxDays: number
  checkDisputePortalExistence?: string
}

export interface DisputeState {
  canDispute: boolean
  message?: string
}

interface DisputeActionError {
  type: "error"
  message: string
}

interface RepaymentAction {
  type: "repayment"
  id: string
}

interface ActiveDispute {
  type: "active"
}

interface CanFile {
  type: "can_file"
  url: Option<string>
  cta: string
}

type DisputeAction = DisputeActionError | RepaymentAction | ActiveDispute | CanFile

export function getDisputeAction(deduction: DeductionResponse, dispute: Option<Dispute>): Option<DisputeAction> {
  if (deduction.is_prepayment) {
    return null
  }
  if (deduction.is_repayment) {
    if (deduction.repayment_for) {
      return {
        type: "repayment",
        id: deduction.repayment_for,
      }
    } else {
      return null
    }
  }
  // prevents flash on render
  if (deduction.dispute_id && !dispute) {
    return null
  }
  if (dispute && dispute.type !== DisputeType.Backup) {
    return {
      type: "active",
    }
  }
  let daysSinceInvoice = differenceInDays(new Date(), new Date(deduction.check_date || deduction.invoice_date))
  // extend windows for super coffee
  let extendedWindow = deduction.org_id === "01910f2d-42da-6dfe-8709-9c52567fbb12"
  if (deduction.source === "cvs") {
    let window = 45
    if (daysSinceInvoice > window) {
      return {
        type: "error",
        message: `Cannot dispute - older than ${window} days`,
      }
    }
    if (deduction.original_source === "CVS Traverse") {
      return {
        type: "can_file",
        url: `https://cvs.traversesystems.com/#/entry/dispute?keyNum=${deduction.invoice_number}`,
        cta: "File Dispute (CVS)",
      }
    } else {
      return {
        type: "error",
        message: "Cannot dispute - no dispute portal found",
      }
    }
  } else if (deduction.source === "kehe") {
    let window = extendedWindow ? 180 * 4 : 180
    if (daysSinceInvoice > window) {
      return {
        type: "error",
        message: `Cannot dispute - older than ${window} days`,
      }
    } else {
      return {
        type: "can_file",
        url: null,
        cta: "File Dispute",
      }
    }
  } else if (deduction.source === "unfi") {
    let window = extendedWindow ? 365 * 2 : 366
    if (daysSinceInvoice > window) {
      return {
        type: "error",
        message: `Cannot dispute - older than ${window} days`,
      }
    } else {
      return {
        type: "can_file",
        url: null,
        cta: "File Dispute",
      }
    }
  } else {
    return {
      type: "can_file",
      url: null,
      cta: "File Dispute",
    }
  }
}

export function getCvsDisputeUrl(deduction: DeductionResponse): string {
  return `https://cvs.traversesystems.com/#/entry/dispute?keyNum=${deduction.invoice_number}`
}

if (import.meta.vitest) {
  const { describe, expect, it } = import.meta.vitest

  // todo: let's make the shield / button yellow if outside the time window but don't need to hide it entirely
  describe("dispute_utils", () => {
    describe("getDisputeAction", () => {
      // @ts-ignore
      const baseDeduction: DeductionResponse = {
        id: "1",
        invoice_number: "12345",
        source: "cvs",
        invoice_date: new Date().toISOString(),
        original_source: "CVS Traverse",
        dispute_id: null,
        is_prepayment: false,
      } as DeductionResponse

      describe("general dispute rules", () => {
        it("should not allow dispute if deduction has dispute_id", () => {
          const deduction = { ...baseDeduction, dispute_id: "123" }
          expect(getDisputeAction(deduction, null)).toEqual(null)
        })

        it("allow dispute if deduction has dispute_id, but the dispute is a backup", () => {
          const deduction = { ...baseDeduction, dispute_id: "123" }
          let dispute = { type: "backup" } as Dispute
          expect(getDisputeAction(deduction, dispute)).toEqual({
            cta: "File Dispute (CVS)",
            type: "can_file",
            url: "https://cvs.traversesystems.com/#/entry/dispute?keyNum=12345",
          })
        })

        it("forbid dispute if deduction has dispute_id, and dispute is chargeback", () => {
          const deduction = { ...baseDeduction, dispute_id: "123" }
          let dispute = { type: "chargeback" } as Dispute
          expect(getDisputeAction(deduction, dispute)).toEqual({
            type: "active",
          })
        })

        it("should not allow dispute if deduction is prepayment", () => {
          const deduction = { ...baseDeduction, is_prepayment: true }
          expect(getDisputeAction(deduction, null)).toEqual(null)
        })

        it("should allow dispute for unknown distributors", () => {
          const deduction = { ...baseDeduction, source: "unknown" }
          expect(getDisputeAction(deduction, null)).toEqual({
            "cta": "File Dispute",
            type: "can_file",
            url: null,
          })
        })
      })

      describe("CVS dispute rules", () => {
        it("should allow dispute for CVS within 45 days with Traverse portal", () => {
          const deduction = {
            ...baseDeduction,
            source: "cvs",
            invoice_date: new Date().toISOString(),
            original_source: "CVS Traverse",
          }
          expect(getDisputeAction(deduction, null)).toEqual({
            type: "can_file",
            url: "https://cvs.traversesystems.com/#/entry/dispute?keyNum=12345",
            cta: "File Dispute (CVS)",
          })
        })

        it("should not allow dispute for CVS older than 45 days", () => {
          const oldDate = addDays(new Date(), -46).toISOString()
          const deduction = {
            ...baseDeduction,
            source: "cvs",
            invoice_date: oldDate,
          }
          expect(getDisputeAction(deduction, null)).toEqual({
            type: "error",
            message: "Cannot dispute - older than 45 days",
          })
        })

        it("should not allow dispute for CVS without Traverse portal", () => {
          const deduction = {
            ...baseDeduction,
            source: "cvs",
            original_source: "CVS Other",
          }
          expect(getDisputeAction(deduction, null)).toEqual({
            type: "error",
            message: "Cannot dispute - no dispute portal found",
          })
        })
      })

      describe("KeHE dispute rules", () => {
        it("should allow dispute for KeHE within 180 days", () => {
          const deduction = {
            ...baseDeduction,
            source: "kehe",
            invoice_date: new Date().toISOString(),
          }
          expect(getDisputeAction(deduction, null)).toEqual({
            type: "can_file",
            cta: "File Dispute",
            url: null,
          })
        })

        it("should not allow dispute for KeHE older than 180 days", () => {
          const oldDate = addDays(new Date(), -181).toISOString()
          const deduction = {
            ...baseDeduction,
            source: "kehe",
            invoice_date: oldDate,
          }
          expect(getDisputeAction(deduction, null)).toEqual({
            type: "error",
            message: "Cannot dispute - older than 180 days",
          })
        })
      })

      describe("UNFI dispute rules", () => {
        it("should allow dispute for UNFI within 365 days", () => {
          const deduction = {
            ...baseDeduction,
            source: "unfi",
            invoice_date: new Date().toISOString(),
          }
          expect(getDisputeAction(deduction, null)).toEqual({
            type: "can_file",
            cta: "File Dispute",
            url: null,
          })
        })

        it("should not allow dispute for UNFI older than 365 days", () => {
          const oldDate = addDays(new Date(), -367).toISOString()
          const deduction = {
            ...baseDeduction,
            source: "unfi",
            invoice_date: oldDate,
          }
          expect(getDisputeAction(deduction, null)).toEqual({
            type: "error",
            message: "Cannot dispute - older than 366 days",
          })
        })
      })
    })

    describe("getCvsDisputeUrl", () => {
      it("should generate correct CVS dispute URL", () => {
        const deduction = {
          invoice_number: "12345",
        } as DeductionResponse

        expect(getCvsDisputeUrl(deduction)).toBe(
          "https://cvs.traversesystems.com/#/entry/dispute?keyNum=12345",
        )
      })
    })
  })
}
