import { DataTableState } from '@/deductions/table_state';
import { putTransactionType } from '@/global_filter';
import { Activity } from '@/stores/activity';
import { displayFormattedDistributor } from '@/utils';
import { formatDistanceToNow, subMonths } from 'date-fns';
import {
  AlertCircle,
  Bell,
  CheckCircle2,
  FileText,
  MessageCircle,
  Upload
} from 'lucide-react';
import { Link } from 'wouter-preact';

interface ActivityItemProps {
  activity: Activity;
  onClose?: () => void;
  isStandalone?: boolean;
}

function getActivityIcon(type: Activity['activity_type']) {
  switch (type) {
    case 'status_change':
      return <CheckCircle2 className="h-5 w-5 text-green-500" />;
    case 'new_import':
      return <Upload className="h-5 w-5 text-blue-500" />;
    case 'task_update':
      return <FileText className="h-5 w-5 text-orange-500" />;
    case 'new_message':
      return <MessageCircle className="h-5 w-5 text-purple-500" />;
    case 'dispute_created':
      return <AlertCircle className="h-5 w-5 text-green-500" />;
    default:
      return <Bell className="h-5 w-5 text-gray-500" />;
  }
}

function formatDisputeType(type: string): string {
  switch (type?.toLowerCase()) {
    case 'chargeback':
      return 'dispute';
    case 'backup':
      return 'backup request';
    case 'earlypay':
      return 'early pay dispute';
    case 'unknown':
      return 'placeholder dispute';
    default:
      return type?.toLowerCase() || 'dispute';
  }
}

function handleBulkStatusClick(status: string, onClose?: () => void, isStandalone?: boolean) {
  DataTableState.set(s => {
    const newState = {
      ...s,
      search: "",
      transactionTypes: putTransactionType(s.transactionTypes, "deduction"),
      columnFilters: [{ id: "status_value", value: status }],
      pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
      startDate: subMonths(new Date(), 12),
      endDate: new Date(),
    };
    
    if (onClose && !isStandalone) {
      setTimeout(onClose, 0);
    }
    
    return newState;
  });

  // Only redirect in standalone mode
  if (isStandalone) {
    window.location.href = '/deductions';
  }
}

function handleBulkTaskClick(taskType: string, status: string | undefined, onClose?: () => void, isStandalone?: boolean) {
  DataTableState.set(s => {
    const newState = {
      ...s,
      search: "",
      transactionTypes: putTransactionType(s.transactionTypes, "deduction"),
      columnFilters: [
        ...(status ? [{ id: "task", value: status }] : [])
      ],
      pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
      startDate: subMonths(new Date(), 12),
      endDate: new Date(),
    };
    
    if (onClose && !isStandalone) {
      setTimeout(onClose, 0);
    }
    
    return newState;
  });

  // Only redirect in standalone mode
  if (isStandalone) {
    window.location.href = '/deductions';
  }
}

function getActivityContent(activity: Activity, onClose?: () => void, isStandalone?: boolean) {
  const { metadata, activity_type: type } = activity;

  switch (type) {
    case 'status_change':
      if (metadata.deduction_count && metadata.deduction_count > 1) {
        return (
          <button 
            onClick={() => handleBulkStatusClick(metadata.new_status!, onClose, isStandalone)}
            className="hover:underline text-left"
          >
            Changed status to <strong>{metadata.new_status}</strong>
            {` for ${metadata.deduction_count} deductions`}
          </button>
        );
      }
      return (
        <span>
          Changed status to <strong>{metadata.new_status}</strong>
        </span>
      );
    case 'new_import':
      return (
        <span>
          Imported {metadata.deduction_count} new deductions
          {metadata.source ? ` from ${displayFormattedDistributor(metadata.source)}` : ''}
          {metadata.total_amount 
            ? ` totaling ${new Intl.NumberFormat('en-US', { 
                style: 'currency', 
                currency: 'USD' 
              }).format(metadata.total_amount)}` 
            : ''}
        </span>
      );
    case 'task_update':
      if (metadata.task_count && metadata.task_count > 1) {
        return (
          <button 
            onClick={() => handleBulkTaskClick(
              metadata.task_type!, 
              metadata.status, 
              onClose,
              isStandalone
            )}
            className="hover:underline text-left"
          >
            {metadata.status?.toLowerCase() === 'completed' 
              ? `Completed ${metadata.task_count} ${metadata.task_type} tasks`
              : `Was assigned ${metadata.task_count} ${metadata.task_type} tasks${metadata.status ? ` with status ${metadata.status}` : ''}`
            }
          </button>
        );
      }
      return (
        <span>
          {metadata.status?.toLowerCase() === 'completed'
            ? `Completed a ${metadata.task_type} task`
            : `Was assigned a ${metadata.task_type} task${metadata.status ? ` with status ${metadata.status}` : ''}`
          }
        </span>
      );
    case 'new_message':
      return (
        <span>
          {metadata.message_count && metadata.message_count > 1 
            ? `Received ${metadata.message_count} new messages` 
            : 'Received new message'}
        </span>
      );
    case 'dispute_created':
      return (
        <span>
          Created new {formatDisputeType(metadata.type || 'dispute')} for {metadata.invoice_number}
        </span>
      );
    default:
      return <span>Unknown activity</span>;
  }
}

function ActivityItemContent({ activity, onClose, isStandalone }: ActivityItemProps) {
  return (
    <div className="flex gap-4">
      <div className="mt-1">
        {getActivityIcon(activity.activity_type)}
      </div>
      <div className="flex-1 space-y-1">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium">
            {activity.actor_name || 'MarginWiz'}
          </p>
          <span className="text-xs text-muted-foreground">
            {formatDistanceToNow(new Date(activity.created_at), { addSuffix: true })}
          </span>
        </div>
        <p className="text-sm text-muted-foreground">
          {getActivityContent(activity, onClose, isStandalone)}
        </p>
        {activity.metadata.individual_details && (
          <div className="mt-2 text-xs text-muted-foreground space-y-1">
            {activity.metadata.individual_details.map((detail, i) => (
              <div key={i} className="pl-2 border-l-2 border-muted">
                {detail.invoice_number && (
                  <span className="hover:underline">
                    {detail.invoice_number}
                  </span>
                )}
                {detail.invoice_amount && (
                  <span className="ml-2">
                    {new Intl.NumberFormat('en-US', { 
                      style: 'currency', 
                      currency: 'USD' 
                    }).format(detail.invoice_amount)}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export function ActivityItem({ activity, onClose, isStandalone }: ActivityItemProps) {
  const deductionId = activity.metadata.deduction_ids?.[0] || activity.metadata.deduction_id;
  const hasLink = deductionId && 
    (activity.metadata.deduction_count === undefined || 
     activity.metadata.deduction_count <= 1);

  const content = <ActivityItemContent activity={activity} onClose={onClose} isStandalone={isStandalone} />;

  if (hasLink) {
    return (
      <Link href={`/deduction/${deductionId}`}>
        <div className="px-6 py-3 hover:bg-muted/50 cursor-pointer">
          {content}
        </div>
      </Link>
    );
  }

  return (
    <div className="px-6 py-3">
      {content}
    </div>
  );
} 