import { AccountingBreakdown } from "@/accounting/accounting_breakdown"
import { api_fetch } from "@/api"
import { DeductionResponse } from "@/api/deduction.tsx"
import { Navbar } from "@/app/navbar"
import { UserState } from "@/auth/user.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { toast } from "@/components/ui/use-toast"
import { cleanDeductionList } from "@/deductions"
import { StatusFlag } from "@/deductions/status_state.tsx"
import { CardBody } from "@/deductions/summary_cards.tsx"
import { DeductionTable } from "@/deductions/table/data_table.tsx"
import { DataTableState } from "@/deductions/table_state.tsx"
import { ActiveFilterIcon } from "@/global_filter/active_filter_icon"
import { isGuayakiUser, map, toISODateString, useAsyncEffect } from "@/utils"
import { Loader2, WandSparklesIcon } from "lucide-react"
import { useState } from "preact/hooks"
import { entity, persistence } from "simpler-state"
import { columns } from "./accounting_columns.tsx"

interface AccountingStats {
  codedCount: number
  uncodedCount: number
}

// custom filter values
const CODED = "coded"
const UNCODED = "uncoded"

interface AccountingFilters {
  codedFilter: string
}

const initialAccountingState: AccountingFilters = {
  codedFilter: "all",
}

export const AccountingState = entity(initialAccountingState, [
  persistence("accounting_filters_state"),
])

export function Accounting() {
  const statusFlag = StatusFlag.use(sf => sf!)
  const user = UserState.use()

  const [loading, setLoading] = useState(true)
  const [autoCodeLoading, setAutoCodeLoading] = useState(false)
  const [autoSplitAdminFeeLoading, setAutoSplitAdminFeeLoading] = useState(false)
  const [autoSplitRtmLoading, setAutoSplitRtmLoading] = useState(false)
  const [data, setData] = useState<DeductionResponse[]>([])
  const [stats, setStats] = useState<AccountingStats>()

  const accountingState = AccountingState.use()

  const tableState = DataTableState.use()

  const { startDate: start, endDate: end, distributor } = tableState

  function handleSetCodedFilter(filter: string) {
    if (filter === accountingState.codedFilter) {
      AccountingState.set(state => ({ ...state, codedFilter: "all" }))
    } else {
      AccountingState.set(state => ({ ...state, codedFilter: filter }))
    }
  }

  async function handleAutoCode() {
    setAutoCodeLoading(true)
    const res = await api_fetch<{ coded_count: number }>("/auto_code_deductions", {
      method: "POST",
      body: [],
    })

    if (!res.ok) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to auto-code deductions",
      })
      setAutoCodeLoading(false)
      return
    }

    toast({
      title: "Auto-Code Complete",
      description: `Successfully coded ${res.value.data?.coded_count} deduction${
        res.value.data?.coded_count === 1 ? "" : "s"
      }`,
    })

    StatusFlag.set(flag => !flag)
    setAutoCodeLoading(false)
  }

  async function handleAutoSplitRtm() {
    setAutoSplitRtmLoading(true)
    const res = await api_fetch<{ split_count: number }>("/auto_split_rtm", {
      method: "POST",
      body: [],
    })

    if (!res.ok) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to auto-split RTM",
      })
      setAutoSplitRtmLoading(false)
      return
    } else {
      toast({
        title: "Auto-Split Complete",
        description: `Successfully split ${res.value.data?.split_count} RTM${
          res.value.data?.split_count === 1 ? "" : "s"
        }`,
      })
    }
  }

  async function handleAutoSplitAdminFee() {
    setAutoSplitAdminFeeLoading(true)
    const res = await api_fetch<{ split_count: number }>("/auto_split_admin_fee", {
      method: "POST",
      body: [],
    })

    if (!res.ok) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to auto-split admin fees",
      })
      setAutoSplitAdminFeeLoading(false)
      return
    }

    toast({
      title: "Auto-Split Complete",
      description: `Successfully split ${res.value.data?.split_count} admin fee${
        res.value.data?.split_count === 1 ? "" : "s"
      }`,
    })

    StatusFlag.set(flag => !flag)
    setAutoSplitAdminFeeLoading(false)
  }

  useAsyncEffect(async () => {
    const res = await api_fetch<DeductionResponse[]>("/deduction/list", {
      method: "POST",
      body: {
        start_date: map(start, toISODateString),
        end_date: map(end, toISODateString),
        distributor,
        query_type: "accounting",
        transaction_types: tableState.transactionTypes,
      },
    })
    setLoading(false)

    if (!res.ok) {
      throw new Error("Failed to fetch data")
    }

    let data = cleanDeductionList(res.value.data)
    setStats({
      codedCount: data.filter(d => (d.reason_codes ?? []).length > 0).length,
      uncodedCount: data.filter(d => !(d.reason_codes ?? []).length).length,
    })

    if (accountingState.codedFilter === CODED) {
      data = data.filter(d => (d.reason_codes ?? []).length > 0)
    } else if (accountingState.codedFilter === UNCODED) {
      data = data.filter(d => !(d.reason_codes ?? []).length)
    }
    setData(data)
  }, [
    start,
    end,
    distributor,
    statusFlag,
    accountingState.codedFilter,
    tableState.transactionTypes,
  ])

  return (
    <div>
      <Navbar />
      <div className="flex justify-between gap-4 mb-4">
        <div className="grow max-w-xl">
          <Card className="h-full">
            <CardHeader className="flex space-y-0 pb-2">
              <CardTitle className="text-md font-medium">Expense Account Breakdown</CardTitle>
            </CardHeader>
            <CardContent>
              <AccountingBreakdown />
            </CardContent>
          </Card>
        </div>
        <div className="w-48">
          <ProgressCards
            stats={stats}
            codedFilter={accountingState.codedFilter}
            handleSetCodedFilter={handleSetCodedFilter}
          />
          <div className="flex justify-end">
            <div className="flex flex-col gap-2">
            <Button
              className="mt-12"
              variant="tertiary"
              onClick={handleAutoCode}
              disabled={autoCodeLoading}
            >
              {autoCodeLoading
                ? <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                : <WandSparklesIcon className="w-4 h-4 mr-2" />}
              Auto Code
            </Button>
            <Button
              className="mt-4"
              variant="outline"
              onClick={handleAutoSplitAdminFee}
              disabled={autoSplitAdminFeeLoading}
            >
              {autoSplitAdminFeeLoading
                ? <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                : <WandSparklesIcon className="w-4 h-4 mr-2" />}
              Auto Split Admin Fee
              </Button>
              {isGuayakiUser(user?.org_id) && (
                <Button
                  className="mt-4"
                  variant="outline"
                  onClick={handleAutoSplitRtm}
                  disabled={autoSplitRtmLoading}
                >
                  {autoSplitRtmLoading 
                    ? <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    : <WandSparklesIcon className="w-4 h-4 mr-2" />}
                  Auto Split RTM
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <DeductionTable loading={loading} columns={columns} data={data} />
    </div>
  )
}

interface ProgressCardsProps {
  stats?: AccountingStats
  codedFilter: string
  handleSetCodedFilter: (filter: string) => void
}

function ProgressCards({ stats, codedFilter, handleSetCodedFilter }: ProgressCardsProps) {
  if (!stats) {
    return null
  }
  return (
    <div className="flex flex-col gap-4">
      <Card className={`${codedFilter === CODED ? "border-blue-400" : ""}`}>
        <CardBody
          value={stats.codedCount}
          // @ts-ignore
          title={
            <div className="flex items-center">
              Coded
              {codedFilter === CODED && <ActiveFilterIcon />}
            </div>
          }
          color="green-700"
          onClick={() => handleSetCodedFilter(CODED)}
        />
      </Card>
      <Card className={`${codedFilter === UNCODED ? "border-blue-400" : ""}`}>
        <CardBody
          value={stats.uncodedCount}
          // @ts-ignore
          title={
            <div className="flex items-center">
              Uncoded
              {codedFilter === UNCODED && <ActiveFilterIcon />}
            </div>
          }
          color="red-800"
          onClick={() => handleSetCodedFilter(UNCODED)}
        />
      </Card>
    </div>
  )
}
